<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
    >
      <b-card
        class="mb-4"
      >
        <b-card-header>
          <h3>Premium Type
            <b-spinner
              v-if="premiumType !=='' && premiumTypeData.premium_type === ''"
              class="ml-2"
              label="Loading..."
            />
          </h3>
        </b-card-header>
        <b-card-body>
          <validation-observer ref="simplePremiumTypes">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Premium Type"
                    label-for="h-name"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="premium type"
                      :rules="{required,min:1,regex: /^[A-Za-z0-9]+$/}"
                    >
                      <b-form-input
                        v-if="premiumTypeData.isUpdate === false"
                        v-model="premiumTypeData.premium_type"
                        placeholder="Premium Type"
                        :maxlength="5"
                      />
                      <b-form-input
                        v-if="premiumTypeData.isUpdate === true"
                        v-model="premiumTypeData.premium_type"
                        placeholder="Premium Type"
                        readonly="readonly"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Enforce Precedence"
                    label-for="h-enforce_precedence"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-if="premiumTypeData.can_edit_or_delete===true"
                      v-model="premiumTypeData.enforce_precedence"
                      :options="enforcePrecedenceOptions"
                    />
                    <b-form-select
                      v-if="premiumTypeData.can_edit_or_delete===false"
                      v-model="premiumTypeData.enforce_precedence"
                      :options="enforcePrecedenceOptions"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="premiumTypeData.enforce_precedence=== true"
                  cols="12"
                >
                  <b-form-group
                    label="Precedence Premium Type"
                    label-for="h-precedence_premium_type"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-if="premiumTypeData.can_edit_or_delete===true"
                      v-model="premiumTypeData.precedence_premium_type"
                      :options="premiumTypeOptions"
                      required="required"
                    />
                    <b-form-select
                      v-if="premiumTypeData.can_edit_or_delete===false"
                      v-model="premiumTypeData.precedence_premium_type"
                      :options="premiumTypeOptions"
                      required="required"
                      disabled="disabled"
                    />
                    <small class="text-danger">{{ errorsMessage }}</small>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="premiumTypeData.premium_type !== '' && premiumTypeData.can_edit_or_delete===false"
                  cols="12"
                >
                  <b-form-group
                    label="Status"
                    label-for="h-status"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="premiumTypeData.premium_type_status"
                      :options="premium_type_status_options"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="premiumTypeData.premium_type !== '' && premiumTypeData.can_edit_or_delete===true"
                  cols="12"
                >
                  <b-form-group
                    label="Status"
                    label-for="h-status"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="premiumTypeData.premium_type_status"
                      :options="premium_type_status_options"
                    />
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col offset-md="4">
                  <b-button
                    v-if="premiumType !== '' && premiumTypeData.can_edit_or_delete===true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Update
                  </b-button>
                  <b-button
                    v-if="premiumType !== '' && premiumTypeData.can_edit_or_delete===false"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                    @click="closeSidebar()"
                  >
                    Close
                  </b-button>
                  <b-button
                    v-if="premiumType === ''"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Add
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BCardBody, BCardHeader,
  BOverlay, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import { premiumTypes } from '@/views/pages/premium-types/premiumTypes'

import {
  required,
} from '@validations'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  name: 'PremiumTypeSidebar',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BOverlay,
    BSpinner,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isPremiumTypeSidebarActive', 'premiumType'],
    event: 'update:is-premium-type-sidebar-active',
  },
  props: {
    isPremiumTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    premiumType: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  data() {
    return {
      enforcePrecedenceOptions: [
        { value: null, text: 'Please select' },
        { value: false, text: 'No' },
        { value: true, text: 'Yes' },
      ],
      show: true,
      errorsMessage: '',
      required,
      premiumTypeData: {
        premium_type: '',
        enforce_precedence: false,
        precedence_premium_type: '',
        updated_on: '',
        can_edit_or_delete: true,
        isUpdate: false,
        premium_type_status: 'A',
      },
      premium_type_status_options: [
        { value: 'A', text: 'Active' },
        { value: 'I', text: 'Inactive' },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line
    getPremiumType(premiumType) {
      if (premiumType) {
        this.show = true
        this.premiumTypeData = {}
        const { fetchPremiumTypes } = premiumTypes()
        fetchPremiumTypes(premiumType)
          .then(response => {
            this.premiumTypeData = response.data.data
            this.premiumTypeData.updated_on = this.formatDate(this.premiumTypeData.updated_on)
            this.show = false
          })
      }
    },
  },
  mounted() {
    this.show = false
    this.loadPremiumTypes()
    if (this.premiumType !== '') {
      // this.premiumTypeData = {}
      // this.show = true
      const { fetchPremiumType } = premiumTypes()
      fetchPremiumType(this.premiumType)
        .then(response => {
          this.premiumTypeData = response.data.data
          this.premiumTypeData.isUpdate = true
          this.premiumTypeData.updated_on = this.formatDate(this.premiumTypeData.updated_on)
          this.show = false
        })
    } else {
      this.premiumTypeData.isUpdate = false
    }
  },
  methods: {
    loadPremiumTypes() {
      this.premiumTypeOptions = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
    },
    closeSidebar() {
      this.$emit('update:is-premium-type-sidebar-active', false)
      this.$emit('update:refetch-data', true)
      this.show = false
    },
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    onClickChild(value) {
      this.$emit('clicked', value)
    },
    validationForm() {
      this.errorsMessage = ''
      this.$refs.simplePremiumTypes.validate().then(success => {
        if (success) {
          if (this.premiumTypeData.enforce_precedence === true && (this.premiumTypeData.precedence_premium_type === '' || this.premiumTypeData.precedence_premium_type === null)) {
            this.errorsMessage = 'Precedence premium type required'
          } else if (this.premiumTypeData.enforce_precedence === true && this.premiumTypeData.precedence_premium_type === this.premiumTypeData.premium_type) {
            this.errorsMessage = 'Precedence premium type cannot be same'
          } else {
            this.show = true
            const { savePremiumType } = premiumTypes()
            savePremiumType(this.premiumTypeData).then(response => {
              if (this.premiumTypeData.isUpdate === false) {
                store.commit('rules-premium-types/UPDATE_PREMIUM_TYPE', {
                  value: response.data.data.premium_type,
                  text: response.data.data.premium_type,
                  enforce_precedence: response.data.data.enforce_precedence,
                  precedence_premium_type: response.data.data.precedence_premium_type,
                  created_on: this.formatDate(response.data.data.created_on),
                  updated_on: this.formatDate(response.data.data.updated_on),
                })
              }
              this.$emit('update:is-premium-type-sidebar-active', false)
              this.$emit('update:refetch-data', true)
              this.show = false
              // this.$emit('refetch-data', true)
              this.premiumTypeData = {}
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-down',
                props: {
                  title: 'Save Premium Type',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Premium type information saved successfully',
                },
              })
              this.$emit('clicked', true)
            }).catch(error => {
              let text = ''
              if (error.response) {
                text = error.response.data.detail
                // client received an error response (5xx, 4xx)
              } else {
                text = error.message
                // eslint-disable-next-line no-console
                console.log(text)
              }
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-down',
                props: {
                  title: 'Save Premium Type',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text,
                },
              })
              this.show = false
            })
          }
        }
      })
    },
  },
}
</script>
