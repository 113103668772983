import store from '@/store'
/* eslint-disable import/prefer-default-export */
export const premiumTypes = () => {
  // eslint-disable-next-line arrow-body-style
  const fetchPremiumTypes = payload => {
    return store.dispatch('rules-premium-types/fetchPremiumTypes', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchPremiumType = premiumType => {
    return store.dispatch('rules-premium-types/fetchPremiumType', premiumType)
  }

  const savePremiumType = payload => store.dispatch('rules-premium-types/savePremiumType', payload)

  const deletePremiumType = premiumType => store.dispatch('rules-premium-types/deletePremiumType', premiumType)

  return {
    fetchPremiumTypes,
    fetchPremiumType,
    savePremiumType,
    deletePremiumType,
  }
}
