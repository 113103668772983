<template>
  <div>
    <b-card

      class="mb-4"
    >
      <b-card-text>
        <b-row>
          <b-col
            cls="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> <b-spinner
              v-if="show"
              class="ml-2"
              label="Loading..."
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                id="searchTerm"
                v-model="filterQ"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="reloadPage()"
              >
                Search
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-0"
                @click.prevent="addPremiumType()"
              >
                Add
              </b-button>
            </div>
          </b-col>
          <b-col
            cls="12"
            class="mt-2"
          >
            <!-- input search -->
            <vue-good-table
              id="premiumTypeTbl"
              :columns="columns"
              :rows="itemList"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: filterQ,
                trigger: 'enter',
                skipDiacritics: true,
              }"
              :pagination-options="{
                enabled: false,
                perPage:perPage
              }"
              striped
              responsive
              hover
              theme="polar-bear"
              mode="remote"
              @on-row-click="onRowSelected"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  v-if="props.column.field === 'updated_on'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ formatDate(props.row.updated_on) }}</span>
                </div>
                <!-- Column: Actions -->
                <span v-else-if="props.column.field === 'actions'">
                  <b-dropdown
                    v-if="props.row.can_edit_or_delete === false"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="line-height-condensed"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="onRowSelected({ row: {premium_type: props.row.premium_type }})"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">View</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if=" props.row.premium_type_status == 'A'"
                      @click="updateStatus(props.row)"
                    >
                      <feather-icon icon="EyeOffIcon" />
                      <span class="align-middle ml-50">Disable</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if=" props.row.premium_type_status == 'I'"
                      @click="updateStatus(props.row )"
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">Enable</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    v-if="props.row.can_edit_or_delete === true"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="line-height-condensed"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="onRowSelected({ row: {premium_type: props.row.premium_type }})"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="props.row.can_edit_or_delete === true"
                      @click="confirmPremiumTypeDelete({premium_type: props.row.premium_type })"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div />
                </div>
              </template>
            </vue-good-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0 mt-2"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @click="goToPage()"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-sidebar
      id="premium-type-sidebar-right"
      v-model="premiumTypeEditVisible"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      :style="cssVars"
    >
      <premium-type-sidebar
        v-if="premiumTypeEditVisible === true && premiumTypeDelete === false"
        :premium-type="selectedRowType"
        :is-premium-type-sidebar-active.sync="premiumTypeEditVisible"
        :visible="isPremiumTypeSidebarActive"
        @clicked="onClickChild"
      />
    </b-sidebar>
  </div>
</template>

<script>
// import router from '@/router'
import {
  // BTable,
  // BFormCheckbox,
  BCard,
  BCardText,
  BRow,
  BCol,
  // BAvatar,
  // BBadge,
  // BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  VBToggle,
  // BLink,
  BButton,
  BSpinner,
  // BFormSelect,
  // BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { premiumTypes } from './premiumTypes'
import PremiumTypeSidebar from './premiumTypeSidebar.vue'

// import router from "@/router";

export default {
  name: 'SearchList',
  components: {
    VueGoodTable,
    // BTable,
    // BFormCheckbox,
    BCard,
    BCardText,
    BRow,
    BCol,
    // BBadge,
    // BAvatar,
    vSelect,
    // BFormInput,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    // BLink,
    PremiumTypeSidebar,
    BButton,
    BSpinner,
    // BFormSelect,
    // BFormGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      premiumTypeOptions: [],
      keyTyping: false,
      timerCount: 0,
      columnFilters: {},
      columnSort: {
        type: 'desc',
        field: 'updated_on',
      },
      premiumTypeDelete: false,
      dir: false,
      searchTerm: '',
      filterQ: '',
      isPremiumTypeSidebarActive: false,
      refetchData: '',
      show: true,
      itemList: [],
      hasResult: true,
      premiumTypeEditVisible: false,
      premiumTypeSidebarWidth: '70%',
      showMoreInfoVisible: false,
      selectedRowType: '',
      selected: [],
      columns: [
        {
          label: 'Premium Type',
          field: 'premium_type',
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.premiumTypeOptions,
            placeholder: 'Any',
          },
        },
        {
          label: 'Enforce Precedence',
          field: 'enforce_precedence_text',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['Yes', 'No'],
            placeholder: 'Any',
          },
        },
        {
          label: 'Precedence Premium Type',
          field: 'precedence_premium_type',
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.premiumTypeOptions,
            placeholder: 'Any',
          },
        },
        {
          label: 'Updated On',
          field: 'updated_on',
          filterOptions: {
            enabled: true,
            placeholder: 'Search updated on',
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'text-right',
        },
      ],
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 5,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    cssVars() {
      return {
        '--max-width': this.premiumTypeSidebarWidth,
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    isPremiumTypeSidebarActive() {
      this.reloadPage()
    },
    currentPage() {
      this.reloadPage()
    },
    perPage() {
      this.reloadPage()
    },
  },
  mounted() {
    this.reloadPage()
    this.premiumTypeSidebarWidth = '30%'
  },
  beforeCreate() {
    this.premiumTypeOptions = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    this.keyTyping = false
    window.addEventListener('keydown', e => {
      this.keyTyping = true
      if (e.key === 'Enter') {
        this.keyTyping = false
        // this.reloadPage()
      }
    })
  },
  methods: {
    updateStatus(row) {
      this.premiumTypeDelete = true
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to update status of this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.premiumTypeDelete = false
        if (result.value) {
          const { savePremiumType } = premiumTypes()
          if (row.premium_type_status === 'A') {
            // eslint-disable-next-line no-param-reassign
            row.premium_type_status = 'I'
          } else {
            // eslint-disable-next-line no-param-reassign
            row.premium_type_status = 'A'
          }
          // eslint-disable-next-line no-param-reassign
          row.isUpdate = true
          savePremiumType(row)
            .then(() => {
              this.reloadPage()
              this.$swal({
                icon: 'success',
                title: 'Updated!',
                text: 'Premium Type Status has been updated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(error => {
            // eslint-disable-next-line no-console
              console.log(error)
            })
        }
      })
    },
    // ${row.id}
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    onColumnFilter(params) {
      this.columnFilters = {
        premium_type: params.columnFilters.premium_type,
        enforce_precedence_text: params.columnFilters.enforce_precedence_text,
        precedence_premium_type: params.columnFilters.precedence_premium_type,
        updated_on: params.columnFilters.updated_on,
      }
      if (this.keyTyping === false) {
        this.reloadPage()
      }
    },
    onSortChange(params) {
      this.columnSort = {}
      this.columnSort.direction = params[0].type
      this.columnSort.field = params[0].field
      this.reloadPage()
      // console.log(params[0])
      // params[0].sortType - ascending or descending
      // params[0].columnIndex - index of column being sorted
    },
    backToPremiumTypesList() {
      this.filterQ = ''
      this.columnFilters = {}
      this.reloadPage()
    },
    confirmPremiumTypeDelete(row) {
      this.premiumTypeDelete = true
      this.$swal({
        title: 'Are you sure?',
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.premiumTypeDelete = false
        if (result.value) {
          const { deletePremiumType } = premiumTypes()
          deletePremiumType(row.premium_type)
            .then(() => {
              const premiumLatest = []
              this.premiumTypeOptions = store.getters['rules-premium-types/GET_PREMIUM_TYPES']

              for (let i = 0; i < this.premiumTypeOptions.length; i += 1) {
                if (this.premiumTypeOptions[i].value.toUpperCase() !== row.premium_type.toUpperCase()) {
                  premiumLatest.push(this.premiumTypeOptions[i])
                }
              }
              this.premiumTypeOptions = premiumLatest
              store.commit('rules-premium-types/UPDATE_PREMIUM_TYPES', premiumLatest)

              this.reloadPage()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Premium Type has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(error => {
            // eslint-disable-next-line no-console
              console.log(error)
            })
        }
      })
    },

    addPremiumType() {
      this.selectedRowType = ''
      this.premiumTypeEditVisible = true
    },
    onRowSelected(row) {
      this.selectedRowType = row.row.premium_type
      this.premiumTypeEditVisible = this.premiumTypeDelete !== true
    },
    onCheckboxSelected(row) {
      this.selected = row.premiumType
    },
    reloadPage() {
      this.show = true
      // this.itemList = []
      // const searchTerm = router.currentRoute.params.q
      const searchTerm = this.filterQ
      const searchData = {
        columnFilters: this.columnFilters, columnSort: this.columnSort, search: searchTerm, itemsPerPage: this.perPage, currentPage: this.currentPage,
      }
      // itemList = [{'id':111}]
      const { fetchPremiumTypes } = premiumTypes()
      fetchPremiumTypes(searchData)
        .then(response => {
          if (response.data.data.total_records > 0) {
            this.hasResult = true
            this.itemList = response.data.data.records
            this.totalRows = response.data.data.total_records
          } else {
            this.hasResult = false
            this.itemList = []
          }
          this.show = false
        })
    },
    onClickChild(value) {
      if (value) {
        this.reloadPage()
      } else if (value === 'more-info-bt') {
        this.premiumTypeSidebarWidth = '100%'
        this.showMoreInfoVisible = true
      } else if (value === 'few-info-bt') {
        this.premiumTypeSidebarWidth = '30%'
        this.showMoreInfoVisible = false
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-good-table.scss';
@import '~vue-good-table/src/styles/style.scss';
#premium-type-sidebar-right {
  width: var(--max-width);
  //max-width: var(--max-width);
}
#premiumTypeTbl .vgt-table tr td {
  vertical-align: middle;
  padding-top: 5px;
  line-height: 37px;
}
</style>
